import './index.css'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image'
import { Row } from 'react-bootstrap';

const KpopeNavbarComponent = () => {
    return (
      <>
        <Navbar className="color-nav" key={'md'} variant="light" expand={false} >
          <Container fluid>
            <div className="flexRowStart">
              <Navbar.Brand href="/">
                <img src={require('../../assets/kpope_logo.png')} width="150" alt=""/>
              </Navbar.Brand>
              <Navbar.Text variant="dark" style={{fontWeight:'normal', color:'black'}}>
                Digital Library
              </Navbar.Text>
            </div>
          </Container>
        </Navbar>
    </>  
     )
}

export const KpopeNavbar = () => {
  return (
    <>
      <KpopeNavbarComponent />
    </>
  )
}
