import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { GeneralPhysics } from './components/GenenralPhysics'
import { KpopeNavbar } from './components/KpopeNavbar'

function App() {
  return (
    <BrowserRouter>
      <KpopeNavbar />
      <Routes>
        <Route path='/' element={<GeneralPhysics />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
