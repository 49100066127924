import { useRef } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import "../../index.css";
import "./index.css";
import XSeriesData from "./data/XSeries.json";
import YSeriesData from "./data/YSeries.json";
import Quant from "./data/Quant.json";
import ASeriesData from "./data/ASeries.json";
import BSeriesData from "./data/BSeries.json";
import CSeriesData from "./data/CSeries.json";
import ClassicalElectrodynamicsData from "./data/ClassicalElectrodynamics";
import ClassicalMechanicsData from "./data/ClassicalMechanics.json";
import QuantXSeriesData from "./data/QuantXSeries.json";
import QuantYSeriesData from "./data/QuantYSeries.json";
import IntellectualBeautyHidingInPhysicsData from "./data/IntellectualBeautyHidingInPhysics.json";
import KPOPEPhysicsLinks from "./data/Links.json";
import GalileoData from "./data/GalileoToEinstein.json";
import MathematicalApproachesData from "./data/MathematicalApproaches.json";
import ReactGA from "react-ga4";

ReactGA.initialize("G-TXK97ETKD6");

const KpopePhysics = () => {
  return (
    <ListGroup style={{ width: "90%", marginTop: "2rem" }}>
      <ListGroup.Item id="purple-bar" active={false} action href="/">
        The Korea Pragmatist Organization for Physics Education (KPOPE)
      </ListGroup.Item>
      <ListGroup.Item id="font-size" active={false} action href="/">
        <div id="flex-container" style={{ marginBottom: "1.3rem" }}>
          <div id="margin-bottom1" style={{ marginRight: "3rem" }}>
            <a
              id="youtube"
              href={KPOPEPhysicsLinks.subscribe_link}
              onClick={ReactGA.event({
                name: "youtube_click",
                params: {
                  category: "Button Clicks", // Define category
                  action: "click", // Define action
                  label: "KPOPE Youtube Button Click", // Define label
                },
              })}
              className="list-group-item-action"
            >
              Free KPOPE Lecture:{" "}
              <img
                id="img-height"
                src={require("../../assets/kpope_subscribe.jpeg")}
                alt=""
              />
            </a>
          </div>
          <div id="margin-bottom1">
            <a
              id="youtube"
              href={KPOPEPhysicsLinks.orchid_link}
              onClick={ReactGA.event({
                name: "orchid_click",
                params: {
                  category: "Button Clicks", // Define category
                  action: "click", // Define action
                  label: "Orchid Button Click", // Define label
                },
              })}
              className="list-group-item-action"
            >
              Publication List:{" "}
              <img
                id="img-height"
                src={require("../../assets/Orcid_icon.png")}
                alt=""
              ></img>
            </a>
          </div>
          <div id="margin-logos">
            <a
              id="youtube"
              href={KPOPEPhysicsLinks.kpope_naver_cafe_link}
              onClick={ReactGA.event({
                name: "kpope_naver_cafe_link",
                params: {
                  category: "Button Clicks", // Define category
                  action: "click", // Define action
                  label: "KPOPE Naver Cafe Button Click", // Define label
                },
              })}
              className="list-group-item-action"
            >
              KPOPE Classroom at Naver Cafe:{" "}
              <img
                id="img-height"
                src={require("../../assets/naverlogo.png")}
                alt=""
              ></img>
            </a>
          </div>
        </div>
        <div id="flex-container">
          <div id="margin-bottom1" style={{ marginRight: "4.5rem" }}>
            <a
              id="youtube"
              href={KPOPEPhysicsLinks.subscribe_link}
              onClick={ReactGA.event({
                name: "kpope_youtube_subscribe_button",
                params: {
                  category: "Button Clicks", // Define category
                  action: "click", // Define action
                  label: "KPOPE Youtube Subscribe Button Click", // Define label
                },
              })}
              className="list-group-item-action"
            >
              Free Subscription:{" "}
              <img
                id="img-height"
                src={require("../../assets/kpope_subscribe.jpeg")}
                alt=""
              />
            </a>
          </div>
          <div id="margin-bottom1" style={{ marginRight: "3rem" }}>
            <a
              id="youtube"
              href={KPOPEPhysicsLinks.inpsire_link}
              onClick={ReactGA.event({
                name: "inspire_click",
                params: {
                  category: "Button Clicks", // Define category
                  action: "click", // Define action
                  label: "Inspire Button Click", // Define label
                },
              })}
              className="list-group-item-action"
            >
              Particle Physics Papers:{" "}
              <img
                id="img-height"
                src={require("../../assets/inspire.jpeg")}
                alt=""
              ></img>
            </a>
          </div>
          <div id="margin-bottom1">
            <a
              id="youtube"
              href={KPOPEPhysicsLinks.quarkvalley_link}
              onClick={ReactGA.event({
                name: "quarkvalley_cafe_click",
                params: {
                  category: "button clicks", // define category
                  action: "click", // define action
                  label: "Quark Valley Button Click", // Define label
                },
              })}
              className="list-group-item-action"
            >
              Quark Valley Camp:{" "}
              <img
                id="img-height"
                src={require("../../assets/quarkvalley.png")}
                alt=""
              ></img>
            </a>
          </div>
        </div>
      </ListGroup.Item>
    </ListGroup>
  );
};

const XSeries = () => {
  return (
    <ListGroup
      key="XSeriesGroup"
      style={{ marginTop: "2rem", marginRight: "2rem" }}
    >
      <ListGroup.Item
        key="XSeries"
        id="first-bar"
        active={false}
        action
        href="/"
      >
        Series X
      </ListGroup.Item>
      {XSeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="XlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const YSeries = () => {
  return (
    <ListGroup style={{ marginTop: "2rem" }}>
      <ListGroup.Item id="right-bar" active={false} action href="/">
        Series Y
      </ListGroup.Item>
      {YSeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="YlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const QuantumMechanics = () => {
  return (
    <ListGroup style={{ width: "90%", marginTop: "2rem" }}>
      <ListGroup.Item id="purple-bar" active={false} action href="/">
        Relativistic Quantum Mechanics
      </ListGroup.Item>
      {Quant.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="QuantButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const AbsurdityASeries = () => {
  return (
    <ListGroup style={{ marginTop: "2rem", marginRight: "3rem" }}>
      <ListGroup.Item id="first-bar" active={false} action href="/">
        Escape from Scientific Absurdity-Series A
      </ListGroup.Item>
      {ASeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="XlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const AbsurdityBSeries = () => {
  return (
    <ListGroup style={{ marginTop: "2rem" }}>
      <ListGroup.Item id="right-bar" active={false} action href="/">
        Escape from Scientific Absurdity-Series B
      </ListGroup.Item>
      {BSeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="YlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const AbsurdityCSeries = () => {
  return (
    <ListGroup style={{ width: "90%", marginTop: "2rem" }}>
      <ListGroup.Item id="purple-bar" active={false} action href="/">
        Escape from Scientific Absurdity-Series C
      </ListGroup.Item>
      {CSeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart" style={{ marginTop: "0.4rem" }}>
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="button-3"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const ClassicalElectrodynamics = () => {
  return (
    <ListGroup style={{ width: "90%", marginTop: "2rem" }}>
      <ListGroup.Item id="purple-bar" active={false} action href="/">
        Classical Electrodynamics
      </ListGroup.Item>
      {ClassicalElectrodynamicsData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart" style={{ marginTop: "0.4rem" }}>
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="button-3"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const ClassicalMechanics = () => {
  return (
    <ListGroup style={{ width: "90%", marginTop: "2rem" }}>
      <ListGroup.Item id="purple-bar" active={false} action href="/">
        Classical Mechanics
      </ListGroup.Item>
      {ClassicalMechanicsData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart" style={{ marginTop: "0.4rem" }}>
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="button-3"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const QuantXSeries = () => {
  return (
    <ListGroup style={{ marginTop: "2rem", marginRight: "3rem" }}>
      <ListGroup.Item id="first-bar" active={false} action href="/">
        Quantum Mechanics - Series QX
      </ListGroup.Item>
      {QuantXSeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="XlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const QuantYSeries = () => {
  return (
    <ListGroup style={{ marginTop: "2rem" }}>
      <ListGroup.Item id="right-bar" active={false} action href="/">
        Quantum Mechanics - Series QY
      </ListGroup.Item>
      {QuantYSeriesData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="YlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const IntellectualBeautyHidingInPhysics = () => {
  return (
    <ListGroup style={{ width: "90%", marginTop: "2rem" }}>
      <ListGroup.Item id="purple-bar" active={false} action href="/">
        Intellectual Beauty Hiding in Physics
      </ListGroup.Item>
      {IntellectualBeautyHidingInPhysicsData.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart" style={{ marginTop: "0.4rem" }}>
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="button-3"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

const createCourseComponent = (title, data, left = false) => {
  return (
    <ListGroup
      style={{ marginTop: "2rem", marginRight: left ? "2rem" : "0rem" }}
    >
      <ListGroup.Item id="right-bar" active={false} action href="/">
        {title}
      </ListGroup.Item>
      {data.map((lecture) => {
        return (
          <ListGroup.Item key={lecture.title} active={false} action href="/">
            <div id="flexColStart">
              {lecture.title}
              <div id="flexRowStart">
                {lecture.links.map((link) => {
                  return (
                    <Button
                      key={link.link}
                      href={link.link}
                      id="YlectureButton"
                      className="ml-5"
                      size="sm"
                      variant="outline-dark"
                    >
                      {link.text}
                      {link.isYoutube ? (
                        <img
                          src={require("../../assets/youtube.png")}
                          width="15"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../assets/docu.png")}
                          width="15"
                          alt=""
                        />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export const GeneralPhysics = () => {
  const targetRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    // Add as many refs as needed
  ];

  const handleClick = (index) => {
    if (targetRefs[index].current) {
      targetRefs[index].current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const TableOfContents = () => {
    return (
      <ListGroup
        key="documentGroup"
        style={{ width: "90%", marginTop: "2rem" }}
      >
        <ListGroup.Item
          key="document"
          active={false}
          action
          href="/"
          id="first-bar"
        >
          Table of Contents
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(0)}
        >
          From Galileo to Einstein
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(1)}
        >
          Mathematical Approaches for Classical and Quantum Mechanics
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(2)}
        >
          Series X
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(3)}
        >
          Series Y
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(4)}
        >
          Relativistic Quantum Mechanics
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(5)}
        >
          Escape from Scientific Absurdity - Series A
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(6)}
        >
          Escape from Scientific Absurdity - Series B
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(7)}
        >
          Escape from Scientific Absurdity - Series C
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(8)}
        >
          Classical Electrodynamics
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(9)}
        >
          Classical Mechanics
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(10)}
        >
          Quantum Mechanics - QX Series
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(11)}
        >
          Quantum Mechanics - QY Series
        </ListGroup.Item>
        <ListGroup.Item
          key={document.title}
          active={false}
          action
          onClick={() => handleClick(12)}
        >
          Intellectual Beauty Hiding in Classical Mechanics
        </ListGroup.Item>
      </ListGroup>
    );
  };

  return (
    <div>
      <div id="flexCol">
        <KpopePhysics />
        <TableOfContents />
        <div className="list-group" style={{ width: "90%" }}>
          <div id="flexRowStart">
            <div id="rowChild">
              <div id="target-component-1" ref={targetRefs[0]}>
                {createCourseComponent(
                  "From Galileo to Einstein",
                  GalileoData,
                  true
                )}
              </div>
            </div>
            <div id="rowChild">
              <div id="target-component-2" ref={targetRefs[1]}>
                {createCourseComponent(
                  "Mathematical Approaches for Classical and Quantum Mechanics",
                  MathematicalApproachesData
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="list-group" style={{ width: "90%" }}>
          <div id="flexRowStart">
            <div id="rowChild">
              <div id="target-component-3" ref={targetRefs[2]}>
                <XSeries />
              </div>
            </div>
            <div id="rowChild">
              <div id="target-component-4" ref={targetRefs[3]}>
                <YSeries />
              </div>
            </div>
          </div>
        </div>
        <div
          id="list-group target-component-5"
          ref={targetRefs[4]}
          style={{ width: "100%" }}
        >
          <div id="flexRowStretch">
            <QuantumMechanics />
          </div>
        </div>
        <div className="list-group" style={{ width: "90%" }}>
          <div id="flexRowStretch">
            <div id="rowChild">
              <div id="target-component-6" ref={targetRefs[5]}>
                <AbsurdityASeries />
              </div>
            </div>
            <div id="rowChild">
              <div id="target-component-7" ref={targetRefs[6]}>
                <AbsurdityBSeries />
              </div>
            </div>
          </div>
        </div>
        <div
          id="list-group target-component-8"
          ref={targetRefs[7]}
          style={{ width: "100%" }}
        >
          <div id="flexRowStretch">
            <AbsurdityCSeries />
          </div>
        </div>
        <div
          id="list-group target-component-9"
          ref={targetRefs[8]}
          style={{ width: "100%" }}
        >
          <div id="flexRowStretch">
            <ClassicalElectrodynamics />
          </div>
        </div>
        <div
          id="list-group target-component-10"
          ref={targetRefs[9]}
          style={{ width: "100%" }}
        >
          <div id="flexRowStretch">
            <ClassicalMechanics />
          </div>
        </div>
        <div className="list-group" style={{ width: "90%" }}>
          <div id="flexRowStretch">
            <div id="rowChild">
              <div id="target-component-11" ref={targetRefs[10]}>
                <QuantXSeries />
              </div>
            </div>
            <div id="rowChild">
              <div id="target-component-12" ref={targetRefs[11]}>
                <QuantYSeries />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="list-group target-component-12"
        ref={targetRefs[12]}
        style={{ width: "100%" }}
      >
        <div id="flexRowStretch">
          <IntellectualBeautyHidingInPhysics />
        </div>
      </div>
    </div>
  );
};
